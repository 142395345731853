import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom, Observable } from 'rxjs';
import StripePaginationResult from 'src/app/core/domain/stripe_pagination_result';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { GenericException } from 'src/app/core/logic/exception';

import { DietitianId } from '../domain/dietitian';
import { Invoice, InvoiceStatus } from '../domain/subscription/invoice';
import {
  PaymentMethod,
  PaymentMethodType,
} from '../domain/subscription/payment_method';
import {
  StripeProductId,
  StripeSubscriptionId,
  Subscription,
  SubscriptionStatus,
} from '../domain/subscription/subscription';

export interface SubscriptionSchema {
  dietitianId: string;
  stripeSubscriptionId: string | null;
  stripeProductId: string | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  status: SubscriptionStatus;
  periodStart: Timestamp;
  periodEnd: Timestamp;
  paymentMethod: PaymentMethodSchema | null;
}

interface PaymentMethodSchema {
  type: PaymentMethodType;
  owner: string | null;
  cardBrand: string | null;
  cardLast4Digits: string | null;
  sepaBankCode: string | null;
  expMonth: number | null;
  expYear: number | null;
}

@Injectable()
export class SubscriptionRepository {
  private readonly createSubscriptionCheckoutSessionFromCloud: (
    data: unknown,
  ) => Observable<string>;

  private readonly createUpdatePaymentMethodCheckoutSessionFromCloud: (
    data: unknown,
  ) => Observable<string>;

  private readonly cancelSubscriptionsFromCloud: (
    data: unknown,
  ) => Observable<void>;

  private readonly getInvoicesFromCloud: (
    data: unknown,
  ) => Observable<StripePaginationResult<{ [key: string]: unknown }>>;

  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) {
    this.createSubscriptionCheckoutSessionFromCloud =
      this.functions.httpsCallable<unknown, string>(
        'subscription-createSubscriptionCheckoutSession',
      );

    this.createUpdatePaymentMethodCheckoutSessionFromCloud =
      this.functions.httpsCallable<unknown, string>(
        'subscription-createUpdatePaymentMethodCheckoutSession',
      );

    this.cancelSubscriptionsFromCloud = this.functions.httpsCallable<
      unknown,
      void
    >('subscription-cancelSubscriptions');

    this.getInvoicesFromCloud = this.functions.httpsCallable<
      unknown,
      StripePaginationResult<{ [key: string]: unknown }>
    >('subscription-getInvoices');
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<SubscriptionSchema>(
      'subscriptions',
      queryFn,
    );
  }

  toSchema(subscription: Subscription): SubscriptionSchema {
    return {
      dietitianId: subscription.dietitianId.id.toString(),
      stripeSubscriptionId:
        subscription.stripeSubscriptionId?.id.toString() ?? null,
      stripeProductId: subscription.stripeProductId?.id.toString() ?? null,
      createdAt:
        subscription.createdAt !== undefined
          ? Timestamp.fromDate(subscription.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      status: subscription.status,
      periodStart: Timestamp.fromDate(subscription.periodStart),
      periodEnd: Timestamp.fromDate(subscription.periodEnd),
      paymentMethod: subscription.paymentMethod
        ? {
            type: subscription.paymentMethod.type,
            owner: subscription.paymentMethod.owner ?? null,
            cardBrand: subscription.paymentMethod.cardBrand ?? null,
            cardLast4Digits: subscription.paymentMethod.cardLast4Digits ?? null,
            sepaBankCode: subscription.paymentMethod.sepaBankCode ?? null,
            expMonth: subscription.paymentMethod.expMonth ?? null,
            expYear: subscription.paymentMethod.expYear ?? null,
          }
        : null,
    };
  }

  fromSchema(schema: SubscriptionSchema, id: string): Subscription {
    return Subscription.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(schema.dietitianId)),
        stripeSubscriptionId: schema.stripeSubscriptionId
          ? StripeSubscriptionId.create(
              new UniqueEntityID(schema.stripeSubscriptionId),
            )
          : undefined,
        stripeProductId: schema.stripeProductId
          ? StripeProductId.create(new UniqueEntityID(schema.stripeProductId))
          : undefined,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
        status: schema.status,
        periodStart: schema.periodStart.toDate(),
        periodEnd: schema.periodEnd.toDate(),
        paymentMethod: schema.paymentMethod
          ? PaymentMethod.create({
              type: schema.paymentMethod.type,
              owner: schema.paymentMethod.owner ?? undefined,
              cardBrand: schema.paymentMethod.cardBrand ?? undefined,
              cardLast4Digits:
                schema.paymentMethod.cardLast4Digits ?? undefined,
              sepaBankCode: schema.paymentMethod.sepaBankCode ?? undefined,
              expMonth: schema.paymentMethod.expMonth ?? undefined,
              expYear: schema.paymentMethod.expYear ?? undefined,
            })
          : undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async findDietitianSubscriptions(
    dietitianId: string,
  ): Promise<Subscription[]> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietitianId', '==', dietitianId)
          .orderBy('createdAt', 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async findDietitianLatestSubscription(
    dietitianId: string,
  ): Promise<Subscription | undefined> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietitianId', '==', dietitianId)
          .orderBy('createdAt', 'desc')
          .limit(1),
      ).get(),
    );

    if (snap.size == 0) return undefined;

    return this.fromSchema(snap.docs[0].data(), snap.docs[0].id);
  }

  listenSubscription(
    subscriptionId: string,
  ): Observable<SubscriptionSchema | undefined> {
    return this.collection().doc(subscriptionId).valueChanges();
  }

  async findDietitianRunningSubscription(
    dietitianId: string,
  ): Promise<Subscription | undefined> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietitianId', '==', dietitianId)
          .where('status', 'in', [
            SubscriptionStatus.Active,
            SubscriptionStatus.Trialing,
            SubscriptionStatus.PendingCancellation,
            SubscriptionStatus.Unpaid,
            SubscriptionStatus.PastDue,
          ])
          .where('periodEnd', '>=', new Date())
          .orderBy('periodEnd', 'desc')
          .orderBy('createdAt', 'desc'),
      ).get(),
    );

    if (snap.size == 0) return undefined;

    const docs = snap.docs.sort((a, b) =>
      a.data().status.localeCompare(b.data().status),
    );

    return this.fromSchema(docs[0].data(), docs[0].id);
  }

  async createSubscriptionCheckoutSession(
    priceId: string,
    successUrl: string,
    cancelUrl: string,
  ): Promise<string> {
    try {
      const checkoutUrl = await firstValueFrom(
        this.createSubscriptionCheckoutSessionFromCloud({
          priceId,
          successUrl,
          cancelUrl,
        }),
      );

      return checkoutUrl;
    } catch (error) {
      throw new GenericException(error);
    }
  }

  async createUpdatePaymentMethodCheckoutSession(
    successUrl: string,
    cancelUrl: string,
  ): Promise<string> {
    try {
      const checkoutUrl = await firstValueFrom(
        this.createUpdatePaymentMethodCheckoutSessionFromCloud({
          successUrl,
          cancelUrl,
        }),
      );

      return checkoutUrl;
    } catch (error) {
      throw new GenericException(error);
    }
  }

  async cancelSubscriptions(): Promise<void> {
    try {
      await firstValueFrom(this.cancelSubscriptionsFromCloud({}));
    } catch (error) {
      throw new GenericException(error);
    }
  }

  async getInvoices(
    pageSize: number,
    startingAfter: string | null,
    endingBefore: string | null,
  ): Promise<StripePaginationResult<Invoice>> {
    try {
      const result = await firstValueFrom(
        this.getInvoicesFromCloud({
          pageSize,
          startingAfter,
          endingBefore,
        }),
      );
      return {
        ...result,
        results: result.results.map((res) =>
          Invoice.create(
            {
              createdAt: new Date(res['createdAt'] as string),
              status: res['status'] as InvoiceStatus,
              amount: res['amount'] as number,
              pdfUrl: res['pdfUrl'] as string | undefined,
              payUrl: res['payUrl'] as string | undefined,
              number: res['number'] as string | undefined,
              receiptNumber: res['receiptNumber'] as string | undefined,
            },
            new UniqueEntityID(res['id'] as string),
          ),
        ),
      };
    } catch (error) {
      throw new GenericException(error);
    }
  }

  createSubscription(subscription: Subscription): Promise<Subscription> {
    const schema = this.toSchema(subscription);

    return this.collection()
      .add(schema)
      .then((ref) => this.fromSchema(schema, ref.id));
  }
}
