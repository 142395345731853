import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { DietitianId } from 'src/app/data/dietitian/domain/dietitian';
import { PatientId } from 'src/app/data/patient/domain/patient';

import {
  DocumentFileTooBigException,
  InvalidDocumentFileTypeException,
} from './document_exceptions';

export const DOCUMENT_MIME_TYPES = [
  'application/pdf',
  'application/vnd.ms-excel', // xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  'application/msword', // doc,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/vnd.oasis.opendocument.spreadsheet', // ods
  'application/vnd.oasis.opendocument.text', // odt
  'image/jpeg',
  'image/png',
];

export const DOCUMENT_MAX_SIZE = 10 * 1024 * 1024;

export interface DocumentProps {
  dietitianId: DietitianId;
  patientId: PatientId;
  name: string;
  createdAt?: Date;
  updatedAt?: Date;
  url?: string;
  path: string;
  file?: File;
  size: number;
  mimeType: string;
}

export class DocumentId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): DocumentId {
    return new DocumentId(id);
  }
}

export class Document extends Entity<DocumentProps> {
  get documentId(): DocumentId {
    return DocumentId.create(this.id);
  }

  get dietitianId(): DietitianId {
    return this.props.dietitianId;
  }

  get patientId(): PatientId {
    return this.props.patientId;
  }

  get name(): string {
    return this.props.name;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get url(): string | undefined {
    return this.props.url;
  }

  get path(): string | undefined {
    return this.props.path;
  }

  get file(): File | undefined {
    return this.props.file;
  }

  get size(): number {
    return this.props.size;
  }

  get mimeType(): string {
    return this.props.mimeType;
  }

  private constructor(props: DocumentProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: DocumentProps): Document {
    return Document.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: DocumentProps, id?: UniqueEntityID): Document {
    if (props.file) {
      if (!Document.isTypeAllowed(props.file.type)) {
        throw new InvalidDocumentFileTypeException();
      }
      if (!Document.isSizeAllowed(props.file.size)) {
        throw new DocumentFileTooBigException();
      }
    }
    return new Document(props, id);
  }

  public static isTypeAllowed = (type: string): boolean =>
    DOCUMENT_MIME_TYPES.includes(type);

  public static isSizeAllowed = (size: number): boolean =>
    size <= DOCUMENT_MAX_SIZE;

  public static getDietitianPatientDocumentPath(
    dietitianId: string,
    patientId: string,
    documentId: string,
    filename: string,
  ): string {
    return `users/${dietitianId}/patients/${patientId}/documents/${documentId}-${filename}`;
  }
}
