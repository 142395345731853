import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { DietitianId } from '../dietitian';
import { PaymentMethod } from './payment_method';

export const SUBSCRIPTION_FREE_TRIAL_DAYS = 15;

export enum SubscriptionStatus {
  Active = 'active',
  PendingCancellation = 'pending_cancellation',
  Canceled = 'canceled',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
  PastDue = 'past_due',
}
export interface SubscriptionProps {
  stripeSubscriptionId?: StripeSubscriptionId;
  stripeProductId?: StripeSubscriptionId;
  dietitianId: DietitianId;
  createdAt?: Date;
  updatedAt?: Date;
  status: SubscriptionStatus;
  periodStart: Date;
  periodEnd: Date;
  paymentMethod?: PaymentMethod;
}

export class SubscriptionId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): SubscriptionId {
    return new SubscriptionId(id);
  }
}

export class StripeSubscriptionId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): StripeSubscriptionId {
    return new StripeSubscriptionId(id);
  }
}

export class StripeProductId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): StripeProductId {
    return new StripeProductId(id);
  }
}

export class Subscription extends Entity<SubscriptionProps> {
  get subscriptionId(): SubscriptionId {
    return SubscriptionId.create(this.id);
  }

  get stripeSubscriptionId(): StripeSubscriptionId | undefined {
    return this.props.stripeSubscriptionId;
  }

  get stripeProductId(): StripeProductId | undefined {
    return this.props.stripeProductId;
  }

  get dietitianId(): DietitianId {
    return this.props.dietitianId;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get status(): SubscriptionStatus {
    return this.props.status;
  }

  get periodStart(): Date {
    return this.props.periodStart;
  }

  get periodEnd(): Date {
    return this.props.periodEnd;
  }

  get paymentMethod(): PaymentMethod | undefined {
    return this.props.paymentMethod;
  }

  get isActive(): boolean {
    return [
      SubscriptionStatus.Active,
      SubscriptionStatus.Trialing,
      SubscriptionStatus.PendingCancellation,
    ].includes(this.status);
  }

  get isCancellable(): boolean {
    return [SubscriptionStatus.Active].includes(this.status);
  }

  get isPendingCancellation(): boolean {
    return this.status == SubscriptionStatus.PendingCancellation;
  }

  get isTrialing(): boolean {
    return this.status == SubscriptionStatus.Trialing;
  }

  get isUnpaid(): boolean {
    return this.status == SubscriptionStatus.Unpaid;
  }

  get isPastDue(): boolean {
    return this.status == SubscriptionStatus.PastDue;
  }

  get isRunning(): boolean {
    return this.periodEnd.getTime() > Date.now();
  }

  private constructor(props: SubscriptionProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: SubscriptionProps): Subscription {
    return Subscription.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: SubscriptionProps,
    id?: UniqueEntityID,
  ): Subscription {
    return new Subscription(props, id);
  }
}
