import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';

import { NutritionService } from '../../ui/nutrition/nutrition.service';
import { AuthModule } from '../auth/auth.module';
import { NewsCommands } from '../news/domain/news-commands';
import { NewsRepository } from '../news/repositories/news-repository';
import { CustomTrackerEventProvider } from '../patient/domain/events/custom_tracker/custom_tracker_event_provider';
import { PatientModule } from '../patient/patient.module';
import { DiaryCommands } from '../tracker/domain/diary/diary_commands';
import { DiaryRepository } from '../tracker/repositories/diary_repository';
import { CustomTrackerCommands } from './domain/custom_tracker/custom_tracker_commands';
import { DietitianCommands } from './domain/dietitian_commands';
import { DietitianStateProvider } from './domain/dietitian_state_provider';
import { EncouragementCommands } from './domain/encouragement/encouragement_commands';
import { DietitianEventProvider } from './domain/events/dietitian_event_provider';
import { EncouragementEventProvider } from './domain/events/encouragement/encouragement_event_provider';
import { MemoEventProvider } from './domain/events/memo/memo_event_provider';
import { NoteEventProvider } from './domain/events/note/note_event_provider';
import { TemplatesEventProvider } from './domain/events/notes_templates/notes_templates_event_provider';
import { NutritionEventProvider } from './domain/events/nutrition/nutrition_event_provider';
import { NutritionIngredientEventProvider } from './domain/events/nutrition/nutrition_ingredient_event_provider';
import { PatientGroupEventProvider } from './domain/events/patient_group_event_provider';
import { InvitationTemplateCommands } from './domain/invitation_template/invitation-template-commands';
import { MemoCommands } from './domain/memo/memo_commands';
import { MessageTemplateCommands } from './domain/message_template/message-template-commands';
import { NoteCommands } from './domain/note/note_commands';
import { NoteStateProvider } from './domain/note/note_state_provider';
import { NotificationCommands } from './domain/notification/notification_commands';
import { NutritionCommands } from './domain/nutritions/nutrition-commands';
import { PatientGroupCommands } from './domain/patient_group/patient_group_commands';
import { SubscriptionCommands } from './domain/subscription/subscription_commands';
import { SurveyCommands } from './domain/survey/survey-commands';
import { TemplateCommands } from './domain/templates/template_commands';
import { CiqualComponentRepository } from './repositories/ciqual_component.repository';
import { CiqualCompositionRepository } from './repositories/ciqual_composition.repository';
import { CiqualGroupIngredientRepository } from './repositories/ciqual_group_ingredient.repository';
import { CiqualIngredientRepository } from './repositories/ciqual_ingredient.repository';
import { CustomTrackerRepository } from './repositories/custom_tracker_repository';
import { DietitianProfileRepository } from './repositories/dietitian_profile_repository';
import { DietitianRepository } from './repositories/dietitian_repository';
import { EncouragementRepository } from './repositories/encouragement_repository';
import { EncouragementTemplateRepository } from './repositories/encouragement-template-repository';
import { InvitationTemplateRepository } from './repositories/invitation-template-repository';
import { MemoRepository } from './repositories/memo_repository';
import { MessageTemplateRepository } from './repositories/message-template-repository';
import { NoteRepository } from './repositories/note_repository';
import { NotificationRepository } from './repositories/notification_repository';
import { NutritionGoalRepository } from './repositories/nutrition-goal-repository';
import { NutritionIngredientRepository } from './repositories/nutrition-ingredient.repository';
import { NutritionMealRepository } from './repositories/nutrition-meal-repository';
import { NutritionQuantityUnitRepository } from './repositories/nutrition-quantity-unit-repository';
import { NutritionRepository } from './repositories/nutrition-repository';
import { PatientGroupRepository } from './repositories/patient_group_repository';
import { PictureRepository } from './repositories/picture_repository';
import { SubscriptionRepository } from './repositories/subscription_repository';
import { SurveyRepository } from './repositories/survey-repository';
import { TemplateRepository } from './repositories/template_repository';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthModule,
    PatientModule,
    HttpClientModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    DietitianRepository,
    PictureRepository,
    PatientGroupRepository,
    NoteRepository,
    SurveyRepository,
    TemplateRepository,
    MemoRepository,
    NotificationRepository,
    DietitianProfileRepository,
    EncouragementRepository,
    EncouragementTemplateRepository,
    SubscriptionRepository,
    CustomTrackerRepository,
    InvitationTemplateRepository,
    MessageTemplateRepository,
    NewsRepository,
    NutritionRepository,
    NutritionGoalRepository,
    NutritionMealRepository,
    NutritionQuantityUnitRepository,
    NutritionIngredientRepository,
    CiqualComponentRepository,
    CiqualCompositionRepository,
    CiqualGroupIngredientRepository,
    CiqualIngredientRepository,
    DiaryRepository,
    NutritionService,
    DietitianStateProvider,
    DietitianEventProvider,
    PatientGroupEventProvider,
    NoteEventProvider,
    NoteStateProvider,
    TemplatesEventProvider,
    EncouragementEventProvider,
    CustomTrackerEventProvider,
    NutritionEventProvider,
    NutritionIngredientEventProvider,
    DietitianCommands,
    PatientGroupCommands,
    NoteCommands,
    SurveyCommands,
    TemplateCommands,
    EncouragementCommands,
    MemoCommands,
    SubscriptionCommands,
    NotificationCommands,
    CustomTrackerCommands,
    NewsCommands,
    NutritionCommands,
  ],
})
export class DietitianModule {
  static forRoot(): ModuleWithProviders<DietitianModule> {
    return {
      ngModule: DietitianModule,
      providers: [
        {
          provide: DietitianStateProvider,
        },
        {
          provide: NoteStateProvider,
        },
        {
          provide: DietitianEventProvider,
        },
        {
          provide: PatientGroupEventProvider,
        },
        {
          provide: NoteEventProvider,
        },
        {
          provide: TemplatesEventProvider,
        },
        {
          provide: MemoEventProvider,
        },
        {
          provide: EncouragementEventProvider,
        },
        {
          provide: CustomTrackerEventProvider,
        },
        {
          provide: DietitianCommands,
        },
        {
          provide: PatientGroupCommands,
        },
        {
          provide: TemplateCommands,
        },
        {
          provide: EncouragementCommands,
        },
        {
          provide: SubscriptionCommands,
        },
        {
          provide: NotificationCommands,
        },
        {
          provide: CustomTrackerCommands,
        },
        {
          provide: InvitationTemplateCommands,
        },
        {
          provide: MessageTemplateCommands,
        },
        {
          provide: NewsCommands,
        },
        {
          provide: DiaryCommands,
        },
      ],
    };
  }
}
