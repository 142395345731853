import ValueObject from 'src/app/core/domain/value_object';

export enum PaymentMethodType {
  Card = 'card',
  Sepa = 'sepa',
}

export interface PaymentMethodProps {
  type: PaymentMethodType;
  owner?: string;
  cardBrand?: string;
  cardLast4Digits?: string;
  sepaBankCode?: string;
  expMonth: number | undefined;
  expYear: number | undefined;
}

export class PaymentMethod extends ValueObject<PaymentMethodProps> {
  get type(): PaymentMethodType {
    return this.props.type;
  }

  get owner(): string | undefined {
    return this.props.owner;
  }

  get cardBrand(): string | undefined {
    return this.props.cardBrand;
  }

  get cardLast4Digits(): string | undefined {
    return this.props.cardLast4Digits;
  }

  get sepaBankCode(): string | undefined {
    return this.props.sepaBankCode;
  }

  get expMonth(): number | undefined {
    return this.props.expMonth;
  }

  get expYear(): number | undefined {
    return this.props.expYear;
  }

  private constructor(props: PaymentMethodProps) {
    super(props);
  }

  public static create(props: PaymentMethodProps): PaymentMethod {
    return new PaymentMethod(props);
  }
}
