import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { FirebaseError } from 'firebase/app';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(@Inject(Injector) private injector: Injector) {
    // do nothing
  }

  // prevent cyclic dependency error
  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  handleError(error: unknown) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rawError = error as any;
    if (rawError.promise && rawError.rejection) {
      error = rawError.rejection;
    }
    // this prevent display firebase related error
    if (error instanceof FirebaseError) {
      return;
    }
    if (error instanceof Error) {
      const chunkFailedMessage = /(?=.*Loading)(?=.*chunk)(?=.*failed)/;
      const unexpectedRouteMessage =
        /(?=.*Cannot)(?=.*match)(?=.*any)(?=.*routes)/;
      const operationNotSupportedMessage =
        /(?=.*The)(?=.*operation)(?=.*is)(?=.*not)(?=.*supported)/;
      if (
        unexpectedRouteMessage.test(error.message) ||
        operationNotSupportedMessage.test(error.message)
      ) {
        return;
      }

      if (chunkFailedMessage.test(error.message)) {
        if (
          confirm(
            'Une nouvelle version du site est disponible, un rechargement est requis. Voulez-vous recharger la page ?',
          )
        ) {
          window.location.reload();
        }
      } else {
        this.toastrService.clear();
        this.toastrService.error(
          error.message && error.message.length > 0
            ? error.message
            : 'Une erreur est survenue',
          'Erreur',
          {
            onActivateTick: true,
          },
        );
        throw error;
      }
    }
  }
}
