import { DietitianId } from '../../../dietitian/domain/dietitian';
import { PatientId } from '../../../patient/domain/patient';
import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';

export interface CompositionProps {
  dietitianId: DietitianId;
  patientId: PatientId;
  name: string;
  content?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export class CompositionId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): CompositionId {
    return new CompositionId(id);
  }
}

export class Composition extends Entity<CompositionProps> {
  get compositionId(): CompositionId {
    return CompositionId.create(this.id);
  }

  get dietitianId(): DietitianId {
    return this.props.dietitianId;
  }

  get patientId(): PatientId {
    return this.props.patientId;
  }

  get name(): string {
    return this.props.name;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get content(): string | undefined {
    return this.props.content;
  }

  private constructor(props: CompositionProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: CompositionProps): Composition {
    return Composition.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: CompositionProps,
    id?: UniqueEntityID,
  ): Composition {
    return new Composition(props, id);
  }
}
