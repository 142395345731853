import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';

import { GlobalErrorHandler } from './error/global-error-handler';
import { DateToStringPipe } from './pipe/date-to-string.pipe';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';

@NgModule({
  declarations: [DateToStringPipe, SafeHtmlPipe],
  imports: [CommonModule],
  exports: [DateToStringPipe, SafeHtmlPipe],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})
export class CoreModule {}
