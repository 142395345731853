import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { DietitianId } from '../../dietitian/domain/dietitian';
import { DocumentProps } from '../../document/domain/document/document';
import { PatientId } from '../../patient/domain/patient';
import { Composition } from '../domain/composition/composition';
import { CompositionNotFoundException } from '../domain/composition/composition-exceptions';
import {
  GenericException,
  UnAuthorizedException,
} from '../../../core/logic/exception';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';

interface CompositionSchema {
  patientId: string;
  dietitianId: string;
  name: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  content: string | null;
}

@Injectable()
export class CompositionRepository {
  constructor(
    private firestore: AngularFirestore,
    private fireAuth: AngularFireAuth,
    private http: HttpClient,
  ) {
    // do nothing
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<CompositionSchema>(
      'compositions',
      queryFn,
    );
  }

  private toSchema(composition: Composition): CompositionSchema {
    return <CompositionSchema>{
      dietitianId: composition.dietitianId.id.toString(),
      patientId: composition.patientId.id.toString(),
      name: composition.name,
      createdAt:
        composition.createdAt !== undefined
          ? Timestamp.fromDate(composition.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      content: composition.content ?? null,
    };
  }

  private fromSchema(schema: CompositionSchema, id: string): Composition {
    return Composition.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(schema.dietitianId)),
        patientId: PatientId.create(new UniqueEntityID(schema.patientId)),
        name: schema.name,
        createdAt: schema.createdAt?.toDate(),
        updatedAt: schema.updatedAt?.toDate(),
        content: schema.content ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async load(compositionId: string): Promise<Composition> {
    const snap = await firstValueFrom(
      this.collection().doc(compositionId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new CompositionNotFoundException();
    }

    return this.fromSchema(snap.data() as CompositionSchema, snap.id);
  }

  async findByDietitianId(
    dietitianId: string,
    asc = false,
  ): Promise<Composition[]> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietitianId', '==', dietitianId)
          .orderBy('updatedAt', asc ? 'asc' : 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async findByPatientId(
    dietitianId: string,
    patientId: string,
    asc = false,
  ): Promise<Composition[]> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietitianId', '==', dietitianId)
          .where('patientId', '==', patientId)
          .orderBy('updatedAt', asc ? 'asc' : 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async create(composition: Composition): Promise<Composition> {
    const schema = this.toSchema(composition);
    const ref = await this.collection().add(schema);

    return this.fromSchema(schema, ref.id);
  }

  async save(composition: Composition): Promise<Composition> {
    const schema = this.toSchema(composition);
    return this.collection()
      .doc(composition.compositionId.id.toString())
      .set(schema)
      .then(() =>
        this.fromSchema(
          schema,
          composition.compositionId.id.toString(),
        ).copyWith({} as DocumentProps),
      );
  }

  async delete(compositionId: string): Promise<void> {
    const snap = await firstValueFrom(
      this.collection().doc(compositionId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new CompositionNotFoundException();
    }
    return this.collection().doc(compositionId).delete();
  }

  async generatePdf(compositionId: string): Promise<Blob> {
    const currentUser = await this.fireAuth.currentUser;
    const token = currentUser ? await currentUser.getIdToken() : undefined;
    if (!token) {
      throw new UnAuthorizedException();
    }

    let result: HttpResponse<Blob>;
    try {
      result = await firstValueFrom(
        this.http.post(
          environment.firebaseFunctionsUrl +
            'composition-generateCompositionPdf',
          {
            compositionId: compositionId,
          },
          {
            headers: new HttpHeaders({
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              Accept: 'application/pdf, application/json',
            }),
            observe: 'response',
            responseType: 'blob',
          },
        ),
      );
    } catch (error: unknown) {
      if (
        error instanceof HttpErrorResponse &&
        error.error instanceof Blob &&
        error.error.type == 'application/json'
      ) {
        const json = JSON.parse(await error.error.text());
        throw new GenericException(json.message);
      }
      throw new GenericException(error);
    }

    if (!result.ok) {
      throw new GenericException(result.statusText);
    }

    if (!result.body) {
      throw new GenericException();
    }

    if (result.body.type == 'application/pdf') {
      return result.body;
    }

    if (result.body.type == 'application/json') {
      const json = JSON.parse(await result.body.text());
      throw new GenericException(json.message);
    }

    throw new GenericException();
  }
}
