import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { DietitianId } from '../../../dietitian/domain/dietitian';
import { Nutrition } from '../../../dietitian/domain/nutritions/nutrition';
import { NutritionGoal } from '../../../dietitian/domain/nutritions/nutrition-goal';
import { NutritionMeal } from '../../../dietitian/domain/nutritions/nutrition-meal';
import { Gender, Patient } from '../patient';

export interface CustomNutritionProps {
  dietitianId: DietitianId;
  patient: Patient;
  nutrition: Nutrition;

  name: string;

  customGender: Gender | undefined;
  customAge: number | undefined;
  customSize: number | undefined;
  customWeight: number | undefined;
  customNAP: number | undefined;
  customBMR: number | undefined;

  customTargetedEnergy: number;
  customProteins: number;
  customLipids: number;
  customGlucids: number;
  customFibers: number | undefined;

  customGoals: NutritionGoal[];
  customMeals: NutritionMeal[];

  customComment: string | undefined;

  withQuantity: boolean | undefined;

  createdAt?: Date;
  updatedAt?: Date;
}

export class CustomNutrition extends Entity<CustomNutritionProps> {
  get nutrition() {
    return this.props.nutrition;
  }

  set nutrition(value: Nutrition) {
    this.props.nutrition = value;
  }

  get patient(): Patient {
    return this.props.patient;
  }

  get name(): string {
    return this.props.name;
  }

  set name(n: string) {
    this.props.name = n;
  }

  get customGender(): Gender | undefined {
    return this.props.customGender;
  }

  set customGender(g: Gender | undefined) {
    this.props.customGender = g;
  }

  get customAge(): number | undefined {
    return this.props.customAge;
  }

  set customAge(a: number | undefined) {
    this.props.customAge = a;
  }

  get customSize(): number | undefined {
    return this.props.customSize;
  }

  set customSize(s: number | undefined) {
    this.props.customSize = s;
  }

  get customWeight(): number | undefined {
    return this.props.customWeight;
  }

  set customWeight(w: number | undefined) {
    this.props.customWeight = w;
  }

  get customNAP(): number | undefined {
    return this.props.customNAP;
  }

  set customNAP(n: number | undefined) {
    this.props.customNAP = n;
  }

  get customBMR(): number | undefined {
    return this.props.customBMR;
  }

  set customBMR(n: number | undefined) {
    this.props.customBMR = n;
  }

  get customTargetedEnergy(): number {
    return this.props.customTargetedEnergy;
  }

  set customTargetedEnergy(v: number) {
    this.props.customTargetedEnergy = v;
  }

  get customProteins(): number {
    return this.props.customProteins;
  }

  set customProteins(v: number) {
    this.props.customProteins = v;
  }

  get customLipids(): number {
    return this.props.customLipids;
  }

  set customLipids(v: number) {
    this.props.customLipids = v;
  }

  get customGlucids(): number {
    return this.props.customGlucids;
  }

  set customGlucids(v: number) {
    this.props.customGlucids = v;
  }

  get customFibers(): number | undefined {
    return this.props.customFibers;
  }

  set customFibers(v: number | undefined) {
    this.props.customFibers = v;
  }

  get customGoals(): NutritionGoal[] {
    return this.props.customGoals;
  }

  set customGoals(goals: NutritionGoal[]) {
    this.props.customGoals = goals;
  }

  get customMeals(): NutritionMeal[] {
    return this.props.customMeals;
  }

  set customMeals(meals: NutritionMeal[]) {
    this.props.customMeals = meals;
  }

  get customComment(): string | undefined {
    return this.props.customComment;
  }

  set customComment(c: string | undefined) {
    this.props.customComment = c;
  }

  get withQuantity(): boolean | undefined {
    return this.props.withQuantity;
  }

  set withQuantity(b: boolean | undefined) {
    this.props.withQuantity = b;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  private constructor(props: CustomNutritionProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: CustomNutritionProps): CustomNutrition {
    return CustomNutrition.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: CustomNutritionProps,
    id?: UniqueEntityID,
  ): CustomNutrition {
    return new CustomNutrition(props, id);
  }
}
