import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import StripePaginationResult from 'src/app/core/domain/stripe_pagination_result';
import { UnAuthorizedException } from 'src/app/core/logic/exception';

import {
  SubscriptionRepository,
  SubscriptionSchema,
} from '../../repositories/subscription_repository';
import { DietitianStateProvider } from '../dietitian_state_provider';
import { SubscriptionEventProvider } from '../events/subscription/subscription_event_provider';
import { SubscriptionCancelled } from '../events/subscription/subscription_events';
import { Invoice } from './invoice';
import { Subscription, SubscriptionProps } from './subscription';

@Injectable()
export class SubscriptionCommands {
  constructor(
    private repository: SubscriptionRepository,
    private subscriptionEventProvider: SubscriptionEventProvider,
    private dietitianStateProvider: DietitianStateProvider,
    private toastr: ToastrService,
  ) {
    this.subscriptionEventProvider.events$.subscribe((event) => {
      if (event instanceof SubscriptionCancelled) {
        this.toastr.success('Abonnement annulé');
      }
    });
  }

  getRunningSubscriptions(): Promise<Subscription | undefined> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    return this.repository.findDietitianRunningSubscription(
      this.dietitianStateProvider.state.entity.id.toString(),
    );
  }

  getLatestSubscription(): Promise<Subscription | undefined> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    return this.repository.findDietitianLatestSubscription(
      this.dietitianStateProvider.state.entity.id.toString(),
    );
  }

  createSubscriptionCheckoutSession(
    priceId: string,
    successUrl: string,
    cancelUrl: string,
  ): Promise<string> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    return this.repository.createSubscriptionCheckoutSession(
      priceId,
      successUrl,
      cancelUrl,
    );
  }

  createUpdatePaymentMethodCheckoutSession(
    successUrl: string,
    cancelUrl: string,
  ): Promise<string> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    return this.repository.createUpdatePaymentMethodCheckoutSession(
      successUrl,
      cancelUrl,
    );
  }

  listenSubscription(
    subscriptionId: string,
  ): Observable<SubscriptionSchema | undefined> {
    return this.repository.listenSubscription(subscriptionId);
  }

  async cancelSubscriptions(): Promise<void> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    await this.repository.cancelSubscriptions();

    this.subscriptionEventProvider.dispatch(
      new SubscriptionCancelled(Subscription.create({} as SubscriptionProps)),
    );
  }

  getInvoices(
    pageSize: number,
    startingAfter: string | null,
    endingBefore: string | null,
  ): Promise<StripePaginationResult<Invoice>> {
    if (this.dietitianStateProvider.state.entity === undefined) {
      throw new UnAuthorizedException();
    }

    return this.repository.getInvoices(pageSize, startingAfter, endingBefore);
  }
}
