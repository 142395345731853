import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom, Observable } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { GenericException } from 'src/app/core/logic/exception';

import { UserId } from '../../auth/domain/user';
import {
  Dietitian,
  DietitianProps,
  Gender,
  StripeCustomerId,
} from '../domain/dietitian';
import { DietitianNotFoundException } from '../domain/dietitian_exceptions';
import { Picture } from '../domain/picture/picture';
import { SubscriptionRepository } from './subscription_repository';

export interface DietitianSchema {
  userId: string;
  stripeCustomerId: string | null;
  email: string;
  emailVerified: boolean;
  notificationToken: string | null;
  firstName: string;
  lastName: string;
  createdAt: Timestamp | null;
  updatedAt: Timestamp | null;
  patientCount: number;
  archivedPatientCount: number;
  activatedPatientCount: number;
  encouragementCount: number;
  phoneNumber: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  pictureUrl: string | null;
  picturePath: string | null;
  isAdmin: boolean | null;
  gender: Gender | null;
  msdpId: string | null;
  msdpUser: string | null;
  msdpPassword: string | null;
  msdpLinkedAt: Timestamp | null;
  msdpKey: string | null;
  lastNews: Timestamp | null;
  status: string | null;
  adeliNumber: string | null;
  patientsDefaultSort: 'createdAt' | 'updatedAt' | 'lastActiveDate' | null;
}

@Injectable()
export class DietitianRepository {
  private readonly dietitianExistsForEmail: (
    data: unknown,
  ) => Observable<boolean>;
  private readonly setupDietitianCustomerFromCloud: (
    data: unknown,
  ) => Observable<string>;

  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
    private subscriptionRepository: SubscriptionRepository,
  ) {
    this.dietitianExistsForEmail = this.functions.httpsCallable<
      unknown,
      boolean
    >('dietitian-dietitianExistsForEmail');

    this.setupDietitianCustomerFromCloud = this.functions.httpsCallable<
      unknown,
      string
    >('dietitian-setupDietitianCustomer');
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<DietitianSchema>('dietitians', queryFn);
  }

  toSchema(dietitian: Dietitian): DietitianSchema {
    return <DietitianSchema>{
      userId: dietitian.userId.id.toString(),
      stripeCustomerId: dietitian.stripeCustomerId?.id.toString() ?? null,
      email: dietitian.email,
      emailVerified: dietitian.emailVerified ?? false,
      firstName: dietitian.firstName,
      lastName: dietitian.lastName,
      notificationToken: dietitian.notificationToken ?? null,
      createdAt:
        dietitian.createdAt !== undefined
          ? Timestamp.fromDate(dietitian.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      address: dietitian.address,
      city: dietitian.city,
      patientCount: dietitian.patientCount ?? 0,
      archivedPatientCount: dietitian.archivedPatientCount ?? 0,
      activatedPatientCount: dietitian.activatedPatientCount ?? 0,
      encouragementCount: dietitian.encouragementCount ?? 0,
      country: dietitian.country,
      phoneNumber: dietitian.phoneNumber,
      postalCode: dietitian.postalCode,
      pictureUrl: dietitian.picture?.url ?? null,
      picturePath: dietitian.picture?.path ?? null,
      isAdmin: dietitian.isAdmin ?? null,
      gender: dietitian.gender ?? null,
      msdpId: dietitian.msdpId ?? null,
      msdpUser: dietitian.msdpUser ?? null,
      msdpPassword: dietitian.msdpPassword ?? null,
      msdpLinkedAt:
        dietitian.msdpLinkedAt !== undefined
          ? Timestamp.fromDate(dietitian.msdpLinkedAt)
          : Timestamp.now(),
      msdpKey: dietitian.msdpKey ?? null,
      lastNews:
        dietitian.lastNews !== undefined
          ? Timestamp.fromDate(dietitian.lastNews)
          : null,
      status: dietitian.status ?? null,
      adeliNumber: dietitian.adeliNumber ?? null,
      patientsDefaultSort: dietitian.patientsDefaultSort ?? null,
    };
  }

  fromSchema(schema: DietitianSchema): Dietitian {
    return Dietitian.create(
      {
        userId: UserId.create(new UniqueEntityID(schema.userId)),
        stripeCustomerId: schema.stripeCustomerId
          ? StripeCustomerId.create(new UniqueEntityID(schema.stripeCustomerId))
          : undefined,
        email: schema.email,
        emailVerified: schema.emailVerified ?? false,
        firstName: schema.firstName,
        lastName: schema.lastName,
        notificationToken: schema.notificationToken ?? undefined,
        updatedAt: schema.updatedAt?.toDate() ?? undefined,
        createdAt: schema.createdAt?.toDate() ?? undefined,
        address: schema.address,
        city: schema.city,
        patientCount: schema.patientCount ?? 0,
        archivedPatientCount: schema.archivedPatientCount ?? 0,
        activatedPatientCount: schema.activatedPatientCount ?? 0,
        encouragementCount: schema.encouragementCount ?? 0,
        country: schema.country,
        phoneNumber: schema.phoneNumber,
        postalCode: schema.postalCode,
        picture:
          schema.picturePath && schema.pictureUrl
            ? Picture.create({
                url: schema.pictureUrl,
                path: schema.picturePath,
              })
            : undefined,
        isAdmin: schema.isAdmin ?? undefined,
        gender: schema.gender ?? undefined,
        msdpId: schema.msdpId ?? undefined,
        msdpLinkedAt: schema.msdpLinkedAt?.toDate() ?? undefined,
        msdpUser: schema.msdpUser ?? undefined,
        msdpPassword: schema.msdpPassword ?? undefined,
        msdpKey: schema.msdpKey ?? undefined,
        lastNews: schema.lastNews?.toDate() ?? undefined,
        status: schema.status ?? undefined,
        adeliNumber: schema.adeliNumber ?? undefined,
        patientsDefaultSort: schema.patientsDefaultSort ?? undefined,
      },
      new UniqueEntityID(schema.userId),
    );
  }

  async load(dietitianId: string): Promise<Dietitian> {
    const results = await Promise.all([
      firstValueFrom(this.collection().doc(dietitianId).get()),
      this.subscriptionRepository.findDietitianRunningSubscription(dietitianId),
    ]);
    const data = results[0].data();
    if (!data) throw new DietitianNotFoundException();

    return this.fromSchema(data).copyWith({
      subscription: results[1],
    } as DietitianProps);
  }

  async existsForEmail(email: string): Promise<boolean> {
    try {
      const result = await firstValueFrom(
        this.dietitianExistsForEmail({
          email,
        }),
      );
      return result;
    } catch (error) {
      // do nothing
    }
    return false;
  }

  async setupDietitianCustomer(): Promise<void> {
    try {
      await firstValueFrom(this.setupDietitianCustomerFromCloud({}));
    } catch (error) {
      throw new GenericException(error);
    }
  }

  async save(dietitian: Dietitian): Promise<Dietitian> {
    const schema = this.toSchema(dietitian);
    await this.collection().doc(schema.userId).set(schema);
    const subscription =
      await this.subscriptionRepository.findDietitianRunningSubscription(
        dietitian.dietitianId.id.toString(),
      );

    return this.fromSchema(schema).copyWith({
      subscription,
    } as DietitianProps);
  }
}
