import moment from 'moment';

import Entity from '../../../core/domain/entity';
import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { UserId } from '../../auth/domain/user';

// France, Belgique, Suisse, Luxembourg, Maroc, Sénégal, Royaume Uni
export const FEATURED_COUNTRY_CODE = [
  '33',
  '32',
  '41',
  '352',
  '212',
  '221',
  '44',
];

export enum Gender {
  WOMAN = 'WOMAN',
  MAN = 'MAN',
  NB = 'NB',
}

export enum AccountStatus {
  INVITATION_SENT = 'INVITATION_SENT',
  BANNED = 'BANNED',
  BLOCKED = 'BLOCKED',
  DRAFT = 'DRAFT',
  FINALIZED = 'FINALIZED',
}

export interface PatientProps {
  userId?: UserId;
  dietId: string;
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  nextAppointment?: Date;
  birthday?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  archivedAt?: Date;
  lastActiveDate?: Date;
  groupId?: string[];
  gender?: Gender;
  avatarUrl?: string;
  consultationReason?: string;
  status: AccountStatus;
  isActive: boolean;
  goals?: string[];
  unreadDiariesCount: number;
  lastUnreadDiaryCreatedAt: Date | undefined;
  msdpId?: string;
}

export class PatientId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): PatientId {
    return new PatientId(id);
  }
}

export class Patient extends Entity<PatientProps> {
  get patientId(): PatientId {
    return PatientId.create(this.id);
  }

  get userId(): UserId | undefined {
    return this.props.userId;
  }

  get groupId(): string[] | undefined {
    return this.props.groupId;
  }

  set groupId(value: string[] | undefined) {
    this.props.groupId = value;
  }

  get dietId(): string {
    return this.props.dietId;
  }

  get goals(): string[] | undefined {
    return this.props.goals;
  }

  get avatarUrl(): string | undefined {
    return this.props.avatarUrl;
  }

  get firstName(): string {
    return this.props.firstName;
  }

  get lastName(): string {
    return this.props.lastName;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get reversedFullName(): string {
    return `${this.lastName} ${this.firstName}`;
  }

  get email(): string | undefined {
    return this.props.email;
  }

  get birthday(): Date | undefined {
    return this.props.birthday;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get archivedAt(): Date | undefined {
    return this.props.archivedAt;
  }

  set archivedAt(value: Date | undefined) {
    this.props.archivedAt = value;
  }

  get archived(): boolean {
    return this.props.archivedAt != undefined;
  }

  get lastActiveDate(): Date | undefined {
    return this.props.lastActiveDate;
  }

  get nextAppointment(): Date | undefined {
    return this.props.nextAppointment;
  }

  get isAppInstalled(): boolean {
    return this.props.isActive && this.props.status == AccountStatus.FINALIZED;
  }

  get consultationReason(): string | undefined {
    return this.props.consultationReason;
  }

  get accountStatus(): AccountStatus | undefined {
    return this.props.status;
  }

  get phoneNumber(): string | undefined {
    return this.props.phoneNumber;
  }

  get gender(): Gender | undefined {
    return this.props.gender;
  }

  get isActive(): boolean {
    return this.props.isActive;
  }

  get unreadDiariesCount(): number {
    return this.props.unreadDiariesCount;
  }

  get lastUnreadDiaryCreatedAt(): Date | undefined {
    return this.props.lastUnreadDiaryCreatedAt;
  }

  get msdpId(): string | undefined {
    return this.props.msdpId;
  }

  get initials(): string {
    return `${this.firstName.length > 0 ? this.firstName.charAt(0) : ''}${
      this.lastName.length > 0 ? this.lastName.charAt(0) : ''
    }`;
  }

  imc(weight?: number, size?: number): number | undefined {
    if (weight && size) {
      return Math.round((weight / Math.pow(size * 0.01, 2)) * 100) / 100;
    }
    return undefined;
  }

  get age() {
    if (!this.birthday) {
      return;
    }

    return moment().diff(this.birthday, 'years');
  }

  copyWith(props: PatientProps): Patient {
    return Patient.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  updateGroupId(groupId?: string[]): Patient {
    return this.copyWith({
      groupId,
    } as PatientProps);
  }

  markDiariesAsRead(): Patient {
    return this.copyWith({
      unreadDiariesCount: 0,
      lastUnreadDiaryCreatedAt: undefined,
    } as PatientProps);
  }

  private constructor(props: PatientProps, id?: UniqueEntityID) {
    super(props, id);
  }

  public static create(props: PatientProps, id?: UniqueEntityID): Patient {
    return new Patient(props, id);
  }
}
