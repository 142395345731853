import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { frLocale } from 'ngx-bootstrap/locale';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { AppFacade } from './app.facade';
import { AppService } from './app.service';
import { LoadingStatus } from './core/domain/events/state_provider';
import { Dietitian } from './data/dietitian/domain/dietitian';
import { DietitianEventProvider } from './data/dietitian/domain/events/dietitian_event_provider';
import {
  DietitianCreated,
  DietitianSignedIn,
  DietitianSignedOut,
} from './data/dietitian/domain/events/dietitian_events';
import { SubscriptionEventProvider } from './data/dietitian/domain/events/subscription/subscription_event_provider';
import { SubscriptionCancelled } from './data/dietitian/domain/events/subscription/subscription_events';
import { NewsPopupComponent } from './ui/components/news-popup/news-popup.component';
import { SubscriptionBannerType } from './ui/components/subscription-banner/subscription-banner.component';

defineLocale('fr', frLocale);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('trialBanner', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translateY(-100%)',
        }),
        animate(400),
      ]),
      transition(':leave', [
        animate(
          400,
          style({
            opacity: 0,
            transform: 'translateY(-100%)',
          }),
        ),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  private _dietitianStatusSubscription?: Subscription;
  private _subscriptionSub: Subscription;

  public dietitian?: Dietitian;
  public remainingDays: number;
  public bannerType?: SubscriptionBannerType | undefined;

  public bsNewsModalRef?: BsModalRef;

  constructor(
    private facade: AppFacade,
    private router: Router,
    private route: ActivatedRoute,
    private localeService: BsLocaleService,
    private appService: AppService,
    private dietitianEventProvider: DietitianEventProvider,
    private subscriptionEventProvider: SubscriptionEventProvider,
    private modalService: BsModalService,
  ) {
    this.localeService.use('fr');
    moment.locale(window.navigator.language);

    this.dietitianEventProvider.events$.subscribe((event) => {
      if (event instanceof DietitianSignedOut) {
        this._subscriptionSub?.unsubscribe();
        this.bannerType = undefined;
      } else if (
        event instanceof DietitianSignedIn ||
        event instanceof DietitianCreated
      ) {
        this.refreshDietitian();
      }
    });

    this.subscriptionEventProvider.events$.subscribe((event) => {
      if (event instanceof SubscriptionCancelled) {
        this.refreshDietitian();
      }
    });
  }

  ngOnInit(): void {
    // check dietitian status
    this._dietitianStatusSubscription = this.facade.dietitian$.subscribe(
      (state) => {
        // finished loading, force refresh
        if (state.loading != LoadingStatus.LOADING) {
          this.router
            .navigateByUrl(location.href.substring(location.origin.length))
            .then();

          this.dietitian = state.entity;
        }
      },
    );
    this.loadDietitian().then();
  }

  refreshDietitian() {
    this.facade.getCurrentDietitian().then((dietitian) => {
      this.dietitian = dietitian;
      this.displaySubscriptionBanner();
      this.displayNewsPopup(this.dietitian);
      this.listenOnSubscriptionChange().then();
    });
  }

  async loadDietitian() {
    const dietitian = await this.facade.loadCurrentDietitian();
    this.displaySubscriptionBanner();
    this.displayNewsPopup(dietitian);
    this.listenOnSubscriptionChange().then();
  }

  async listenOnSubscriptionChange() {
    if (!this.dietitian?.subscription?.subscriptionId) {
      return;
    }

    this._subscriptionSub = this.facade
      .listenSubscription(
        this.dietitian?.subscription?.subscriptionId?.id?.toString(),
      )
      .subscribe(async (subscription) => {
        if (this.dietitian?.subscription != subscription) {
          await this.facade.loadCurrentDietitian();
          this.displaySubscriptionBanner();
        }
      });
  }

  displaySubscriptionBanner() {
    // user is not connected, skip
    if (!this.dietitian) {
      return;
    }

    if (
      !this.dietitian?.subscription ||
      (!this.dietitian?.subscription?.isActive &&
        !this.dietitian?.subscription?.isUnpaid &&
        !this.dietitian?.subscription?.isPastDue)
    ) {
      this.bannerType = SubscriptionBannerType.expired;
    } else {
      if (this.dietitian?.subscription.isPendingCancellation) {
        this.bannerType = SubscriptionBannerType.pending_cancel;
        this.remainingDays = moment
          .duration(
            moment(this.dietitian?.subscription?.periodEnd)
              .startOf('days')
              .diff(moment().startOf('days')),
          )
          .asDays();
      } else if (this.dietitian?.subscription.isTrialing) {
        this.bannerType = SubscriptionBannerType.trial;
        this.remainingDays = moment
          .duration(
            moment(this.dietitian?.subscription?.periodEnd)
              .startOf('days')
              .diff(moment().startOf('days')),
          )
          .asDays();
      } else if (this.dietitian?.subscription.isUnpaid) {
        this.bannerType = SubscriptionBannerType.unpaid;
      } else if (this.dietitian?.subscription.isPastDue) {
        this.bannerType = SubscriptionBannerType.unpaid;
      }
    }

    this.refreshBanner();
  }

  displayNewsPopup(dietitian: Dietitian | undefined) {
    // user is not connected, skip
    if (!dietitian) {
      return;
    }

    if (dietitian) {
      this.openNewsPopup(dietitian).then();
    }
  }

  get bannerClass() {
    switch (this.bannerType) {
      case SubscriptionBannerType.trial:
        return 'trial';
      case SubscriptionBannerType.expired:
      case SubscriptionBannerType.unpaid:
        return 'expired';
      case SubscriptionBannerType.pending_cancel:
        return 'pending-cancel';
      default:
        return 'default';
    }
  }

  ngOnDestroy(): void {
    this._dietitianStatusSubscription?.unsubscribe();
    this._subscriptionSub?.unsubscribe();
  }

  closeBanner() {
    this.bannerType = undefined;
    this.refreshBanner();
  }

  refreshBanner() {
    this.appService.setBannerDisplayed(this.bannerType != undefined);
  }

  private async openNewsPopup(dietitian: Dietitian) {
    const news = dietitian.lastNews
      ? await this.facade.findAllNewsAfterDate(dietitian.lastNews)
      : await this.facade.findLastTwoNews();
    if (news && news.length > 0) {
      this.bsNewsModalRef = this.modalService.show(NewsPopupComponent, {
        backdrop: false,
        class: 'modal-lg modal-dialog-centered modal-shadow',
        initialState: { news: news },
      });
      if (this.bsNewsModalRef.content instanceof NewsPopupComponent) {
        this.bsNewsModalRef.content.result.subscribe(async (checked) => {
          if (!checked) {
            this.dietitian = await this.facade.updateDietitianLastNews();
          }
        });
      }
    }
  }
}
